<template>
	<modal class="NoxModalSplash" name="NoxModalSplash" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="(noxType !== 'showSplash' ? true : false)" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close" v-if="noxType !== 'showSplash'"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'addSplash'">Добавление уведомления</span>
					<span v-else-if="noxType == 'editSplash'">Редактирование уведомления</span>
					<span v-else-if="noxType == 'showSplash'" v-html="$store.getters.getLanguageText('5.18', 0)"></span>
					<span v-else-if="noxType == 'deleteSplash'">Удаление уведомления</span>
				</div>
			</div>
			<div class="nox_modal_body">
				<div class="nox_modal_info">
					<div v-if="['addSplash', 'editSplash'].includes(noxType)">
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Точка отсчета:</div>
							<div class="nox_modal_info_row_value">
								<v-select class="nox_select style-chooser" v-model.trim="noxSplashType" placeholder="точка отсчета уведомления" :options="$parent.noxSplashTypes"></v-select>
								<div v-html="noxAlertSplashType"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Время отсчета:</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxSplashTime" placeholder="время отсчета уведомления" autocomplete="off">
								<div v-html="noxAlertSplashTime"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Текст (RU):</div>
							<div class="nox_modal_info_row_value">
								<textarea class="nox_textarea small" v-model.trim="noxSplashTextRU" placeholder="html текст уведомления (ru)"></textarea>
								<div v-html="noxAlertSplashTextRU"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Текст (EN):</div>
							<div class="nox_modal_info_row_value">
								<textarea class="nox_textarea small" v-model.trim="noxSplashTextEN" placeholder="html текст уведомления (en)"></textarea>
								<div v-html="noxAlertSplashTextEN"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Приоритет:</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxSplashPriority" placeholder="приоритет уведомления" autocomplete="off">
								<div v-html="noxAlertSplashPriority"></div>
							</div>
						</div>
						<div class="nox_modal_info_row last">
							<div class="nox_modal_info_row_label">Статус:</div>
							<div class="nox_modal_info_row_value">
								<toggle-button class="nox_toggle_button" :value="toggleValue(noxSplashIsActive)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForIsActive"></toggle-button>
								<div v-html="noxAlertSplashIsActive"></div>
							</div>
						</div>
					</div>
					<div class="nox_modal_info_splash" v-else-if="noxType == 'showSplash'" v-html="$parent.noxSplashData['text_' + $store.state.noxLanguage]"></div>
					<div v-else-if="noxType == 'deleteSplash'">Вы действительно хотите удалить уведомление?</div>
				</div>
				<div class="nox_modal_alert" v-html="noxAlert"></div>
				<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
					<button type="button" class="nox_button common" @click="axios('repeatSplash')" v-if="noxType == 'showSplash'" v-html="$store.getters.getLanguageText('1.1', 119)"></button>
					<button type="button" class="nox_button common" @click="close" v-else v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-if="noxType == 'addSplash'" v-html="$store.getters.getLanguageText('1.1', 5)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-else-if="noxType == 'editSplash'" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
					<button type="button" class="nox_button common" @click="axios('confirmSplash')" v-else-if="noxType == 'showSplash'" v-html="$store.getters.getLanguageText('1.1', 120)"></button>
					<button type="button" class="nox_button common red" @click="axios" v-else-if="noxType == 'deleteSplash'" v-html="$store.getters.getLanguageText('1.1', 11)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertSplashType: '',
			noxAlertSplashTime: '',
			noxAlertSplashTextRU: '',
			noxAlertSplashTextEN: '',
			noxAlertSplashPriority: '',
			noxAlertSplashIsActive: '',
			noxId: 0,
			noxType: '',
			noxIndex: 0,
			noxSplashType: '',
			noxSplashTime: '',
			noxSplashTextRU: '',
			noxSplashTextEN: '',
			noxSplashPriority: '',
			noxSplashIsActive: 0,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxId = event.params.id;
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxSplashType = '';
				this.noxSplashTime = '';
				this.noxSplashTextRU = '';
				this.noxSplashTextEN = '';
				this.noxSplashPriority = '';
				this.noxSplashIsActive = 0;
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (this.noxType == 'editSplash') {

					this.noxSplashType = this.$parent.noxSplashData[this.noxIndex].type;
					this.noxSplashType = this.noxSplashType ? this.$parent.noxSplashTypes[this.noxSplashType - 1] : '';
					this.noxSplashTime = this.$parent.noxSplashData[this.noxIndex].time;
					this.noxSplashTextRU = this.$parent.noxSplashData[this.noxIndex].text_ru;
					this.noxSplashTextEN = this.$parent.noxSplashData[this.noxIndex].text_en;
					this.noxSplashPriority = this.$parent.noxSplashData[this.noxIndex].priority;
					this.noxSplashIsActive = this.$parent.noxSplashData[this.noxIndex].is_active;
				}
				else if (this.noxType == 'showSplash') {
					if (this.$store.state.noxModalIntervalId) {
						clearInterval(this.$store.state.noxModalIntervalId);
					}
				}
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertSplashType = '';
				this.noxAlertSplashTime = '';
				this.noxAlertSplashTextRU = '';
				this.noxAlertSplashTextEN = '';
				this.noxAlertSplashPriority = '';
				this.noxAlertSplashIsActive = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.20', 0); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.20', 1); }
				else if (i == 3) { this.noxTemp = 'Необходимо выбрать точку отсчета уведомления.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо выбрать верную точку отсчета уведомления.'; }
				else if (i == 5) { this.noxTemp = 'Необходимо указать время отсчета уведомления.'; }
				else if (i == 6) { this.noxTemp = 'Необходимо указать верное время отсчета уведомления.'; }
				else if (i == 7) { this.noxTemp = 'Необходимо указать HTML текст уведомления (ru).'; }
				else if (i == 8) { this.noxTemp = 'Необходимо указать верный HTML текст уведомления (ru).'; }
				else if (i == 9) { this.noxTemp = 'Необходимо указать HTML текст уведомления (en).'; }
				else if (i == 10) { this.noxTemp = 'Необходимо указать верный HTML текст уведомления (en).'; }
				else if (i == 11) { this.noxTemp = 'Необходимо указать приоритет уведомления.'; }
				else if (i == 12) { this.noxTemp = 'Необходимо указать верный приоритет уведомления.'; }
				else if (i == 13) { this.noxTemp = 'Необходимо указать статус уведомления.'; }
				else if (i == 14) { this.noxTemp = 'Необходимо указать верный статус уведомления.'; }
				else if (i == 15) { this.noxTemp = this.$store.getters.getLanguageText('1.3.20', 2); }
				else if (i == 16) { this.noxTemp = 'Такой приоритет уведомления уже есть в системе.'; }
				else              { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (['addSplash', 'editSplash'].includes(_this.noxType)) {
						if (!_this.noxSplashType) {
							_this.noxAlertSplashType = _this.getError(3);
						}
						if (!_this.noxSplashTime) {
							_this.noxAlertSplashTime = _this.getError(5);
						}
						else if (!_this.$store.state.noxRegex_d.test(_this.noxSplashTime) || _this.noxSplashTime < 1) {
							_this.noxAlertSplashTime = _this.getError(6);
						}
						if (!_this.noxSplashTextRU) {
							_this.noxAlertSplashTextRU = _this.getError(7);
						}
						if (!_this.noxSplashTextEN) {
							_this.noxAlertSplashTextEN = _this.getError(9);
						}
						if (!_this.noxSplashPriority) {
							_this.noxAlertSplashPriority = _this.getError(11);
						}
						else if (!_this.$store.state.noxRegex_d.test(_this.noxSplashPriority) || _this.noxSplashPriority < 1) {
							_this.noxAlertSplashPriority = _this.getError(12);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/splash';
							config.data = { id: _this.noxId, type: _this.noxSplashType.code, time: _this.noxSplashTime, text_ru: _this.noxSplashTextRU, text_en: _this.noxSplashTextEN, priority: _this.noxSplashPriority, is_active: _this.noxSplashIsActive };
							config.method = _this.noxType == 'addSplash' ? 'post' : 'patch';
						}
					}
					else if (_this.noxType == 'showSplash') {
						if (type == 'repeatSplash') {
							config.url = '/v2/account/splash/repeat';
							config.data = { id: _this.noxId };
							config.method = 'patch';
						}
						else if (type == 'confirmSplash') {
							config.url = '/v2/account/splash/confirm';
							config.data = { id: _this.noxId };
							config.method = 'patch';
						}
					}
					else if (_this.noxType == 'deleteSplash') {
						config.url = '/v2/admin/account/splash';
						config.data = { id: _this.noxId };
						config.method = 'delete';
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.noxType == 'addSplash') {
									_this.$parent.noxSplashData.push(data.data);
								}
								else if (_this.noxType == 'editSplash') {
									_this.$parent.noxSplashData.splice(_this.noxIndex, 1, data.data);
								}
								else if (_this.noxType == 'deleteSplash') {
									_this.$parent.noxSplashData.splice(_this.noxIndex, 1);
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (['addSplash', 'editSplash', 'showSplash', 'deleteSplash'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'TYPE_IS_EMPTY') { _this.noxAlertSplashType = _this.getError(3); }
										else if (data.response.data.error == 'TYPE_NOT_VALID') { _this.noxAlertSplashType = _this.getError(4); }
										else if (data.response.data.error == 'TIME_IS_EMPTY') { _this.noxAlertSplashTime = _this.getError(5); }
										else if (data.response.data.error == 'TIME_NOT_VALID') { _this.noxAlertSplashTime = _this.getError(6); }
										else if (data.response.data.error == 'TEXT_RU_IS_EMPTY') { _this.noxAlertSplashTextRU = _this.getError(7); }
										else if (data.response.data.error == 'TEXT_RU_NOT_VALID') { _this.noxAlertSplashTextRU = _this.getError(8); }
										else if (data.response.data.error == 'TEXT_EN_IS_EMPTY') { _this.noxAlertSplashTextEN = _this.getError(9); }
										else if (data.response.data.error == 'TEXT_EN_NOT_VALID') { _this.noxAlertSplashTextEN = _this.getError(10); }
										else if (data.response.data.error == 'PRIORITY_IS_EMPTY') { _this.noxAlertSplashPriority = _this.getError(11); }
										else if (data.response.data.error == 'PRIORITY_NOT_VALID') { _this.noxAlertSplashPriority = _this.getError(12); }
										else if (data.response.data.error == 'IS_ACTIVE_IS_EMPTY') { _this.noxAlertSplashIsActive = _this.getError(13); }
										else if (data.response.data.error == 'IS_ACTIVE_NOT_VALID') { _this.noxAlertSplashIsActive = _this.getError(14); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (['editSplash', 'showSplash', 'deleteSplash'].includes(_this.noxType)) {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(15); }
									}
								}
								else if (data.response.status == 409) {
									if (['addSplash', 'editSplash'].includes(_this.noxType)) {
										if (data.response.data.error == 'PRIORITY_DUPLICATE') { _this.noxAlertSplashPriority = _this.getError(16); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChangeForIsActive: function(event) {
				this.noxSplashIsActive = Number(event.value);
			},
			close: function() {
				this.$modal.hide('NoxModalSplash');
			}
		}
	}
</script>
